<template>
  <div v-show="media.length > 0" class="media-library">
    <div class="files-list-container">
      <div v-for="(file, index) in media" :key="index" class="file" :class="{ active: isActive(file) }" @click="selectFile(file)">
        <img :src="file.url" :alt="file.filename">
        <div class="bottom-bar">
          <p :title="file.filename">{{ file.filename }}</p>
          <font-awesome-icon class="delete" icon="trash" />
          <font-awesome-icon class="edit" icon="pen" />
        </div>
      </div>
      <div v-if="!endOfList" class="load-more" @click="listMedia">
        <font-awesome-icon icon="redo" />
        Load more
      </div>
    </div>
    <div class="btn save" :class="{disabled: !this.isValid()}" @click="save">Add</div>
  </div>
</template>

<script>
export default {
  props: {
    selectedFile: Object,
    selectedFilesList: Array,
    multiple: Boolean,
  },
  data: () => ({
    media: [],
    page: 1,
    endOfList: false,
  }),
  created() {
    this.listMedia();
  },
  methods: {
    listMedia() {
      const params = {
        page: this.page,
        per_page: 16,
      };

      this.$api.media
        .list(params)
        .then((response) => {
          for (const item of response.data.data) {
            this.media.push(item);
          }
          if (this.media.length === 0) {
            this.$emit("show-upload");
          }
          if(response.data.pagination.total_pages === this.page) this.endOfList = true;
          this.page++;
        })
        .catch(() => {
        });
    },
    selectFile(file) {
      if (this.multiple) {
        const fileIndex = this.selectedFilesList.findIndex((item) => item.id === file.id);
        if(fileIndex > -1) {
          this.selectedFilesList.splice(fileIndex, 1);
        }
        else {
          this.selectedFilesList.push(file);
        }
      }
      else {
        this.$emit("change", file);
      }
    },
    save() {
      if(this.isValid) this.$emit("input", this.multiple ? this.selectedFilesList : this.selectedFile);
    },
    isActive(file) {
      if (this.multiple) {
        return !!this.selectedFilesList.find((item) => item.id === file.id);
      }
      else {
        return this.selectedFile && this.selectedFile.id === file.id;
      }
    },
    isValid() {
      if (this.multiple) {
        return this.selectedFilesList.length > 0;
      }
      else {
        return !!this.selectedFile
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.media-library {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  gap: 30px;
}

.files-list-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  overflow-y: auto;
  flex-grow: 1;
  max-height: 600px;
  padding: 5px;

  .file {
    width: 280px;
    height: 280px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0 0 0 #EE6055;
    transition: box-shadow .15s ease;

    &.active {
      box-shadow: 0 0 0 5px #EE6055;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .bottom-bar {
      position: absolute;
      width: 100%;
      height: 45px;
      bottom: 0;
      left: 0;
      background: rgba(255,255,255,.6);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 60px;
      
      p {
        width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 0 !important;
      }
      
      svg {
        position: absolute;
        color: #EE6055;
        font-size: 20px;
        right: 10px;
        cursor: pointer;

        &.delete {
          right: 40px;
        }
      }
    }
  }
}

.btn {
  padding: 7px 16px;
  font-weight: 500;
  font-size: 20px;
  border-radius: 22px;
  cursor: pointer;
  width: fit-content;

  &.save {
    background: #EC5F54;
    color: #fff;
  }

  &.disabled {
    opacity: .6;
    cursor: not-allowed;
  }
}

.load-more {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  box-shadow: 0 0 6px 1px rgba(0,0,0,.1);
  cursor: pointer;
  border-radius: 10px;
  font-size: 16px;

  svg {
    font-size: 22px;
  }
}

</style>
