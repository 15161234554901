import axios from "axios";

export const media = {
  list: (params) => axios.get(`/media`, { params }),
  upload: (form) => axios.post(`/media`, form),
  get: (id) => axios.get(`/media/${id}`),
  upload: (form) => axios.post(`/media`, form),
  update: (id, specs) => axios.post(`/media/${id}`, specs),
  delete: (id) => axios.delete(`/media/${id}`),
  multipleDelete: (ids) => axios.post(`/media/multiple/delete`,{ids}),
};
