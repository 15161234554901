<template>
  <div :class="{ disabled: isLoading }">
    <v-layout
      v-if="userAttributes && userAttributes.length"
      class="row wrap"
    >
      <v-flex
        v-for="(attribute, i) in userAttributes"
        :key="'user-attribute-' + i"
        class="xs12"
      >
        <v-layout
          row
          wrap
          class="mb-4"
          v-if="attributeValues"
        >
          <v-flex class="xs8">
            <v-select
              v-if="attribute.type === 'options' && !attribute.is_collection"
              v-model="attributeValues[attribute.slug]"
              :items="
                availableOptionsSingleSelect(attribute.options, attribute.slug)
              "
              item-text="name"
              item-value="id"
              :label="attribute.name"
              :menu-props="{ closeOnContentClick: true }"
              chips
              @input="listGroupUserRelatedAttributes"
              :disabled="disabled"
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  :selected="data.selected"
                  :close="!disabled"
                  @input="removeAttributeOption(attribute, data.item)"
                  :disabled="disabled"
                >
                  {{ data.item.name }}
                  <template v-if="data.item.price">
                    ({{ formatPrice(data.item.price) }})
                  </template>
                </v-chip>
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <div class="text-overflow--wrap py-3">
                  <span>{{ data.item.name }}</span>
                  <span
                    v-if="data.item.price"
                    class="ml-2"
                  >
                    ({{ formatPrice(data.item.price) }})
                  </span>
                </div>
              </template>
            </v-select>
            <v-select
              v-if="attribute.type === 'options' && attribute.is_collection"
              v-model="attributeValues[attribute.slug]"
              :items="
                availableOptionsMultiSelect(attribute.options, attribute.slug)
              "
              multiple
              item-text="name"
              item-value="id"
              :label="attribute.name"
              :menu-props="{ closeOnContentClick: true }"
              chips
              @input="listGroupUserRelatedAttributes"
              :disabled="disabled"
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  :selected="data.selected"
                  :close="!disabled"
                  @input="removeAttributeOption(attribute, data.item)"
                  :disabled="disabled"
                >
                  {{ data.item.name }}
                  <template v-if="data.item.price">
                    ({{ formatPrice(data.item.price) }})
                  </template>
                </v-chip>
              </template>
              <template
                slot="item"
                slot-scope="data"
              >
                <div class="text-overflow--wrap py-3">
                  <span>{{ data.item.name }}</span>
                  <span
                    v-if="data.item.price"
                    class="ml-2"
                  >
                    ({{ formatPrice(data.item.price) }})
                  </span>
                </div>
              </template>
            </v-select>
            <v-textarea
              v-if="attribute.type == 'text'"
              v-model="attributeValues[attribute.slug]"
              :label="attribute.name"
              no-resize
              :disabled="disabled"
            ></v-textarea>
            <v-text-field
              v-if="attribute.type == 'varchar' || attribute.type == 'integer'"
              v-model="attributeValues[attribute.slug]"
              :label="attribute.name"
              :disabled="disabled"
            ></v-text-field>
            <CustomDatePicker
              v-if="attribute.type === 'datetime'"
              v-model="attributeValues[attribute.slug]"
              :label="attribute.name"
              :disabled="disabled"
            >
            </CustomDatePicker>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class="mb-4"
      v-if="pluginPrefix === 'signup'"
    >
      <v-flex
        xs8
        v-if="!user.group_product_purchased_at"
      >
        <TranslatableTooltip
          :content="
            $t(
              'group_user_page.attributes_tab_item_signup_buy_group_product_label_tooltip',
            )
          "
          :disabled="disabled"
        >
          <v-checkbox
            v-model="completeAttributeCategory"
            :label="
              $t(
                'group_user_page.attributes_tab_item_signup_buy_group_product_label',
              )
            "
            hide-details
            class="shrink mt-0"
            :disabled="disabled"
          >
          </v-checkbox>
        </TranslatableTooltip>
      </v-flex>
      <v-flex
        xs8
        class="grey--text"
        v-if="user.group_product_purchased_at"
      >
        <TranslatableTooltip
          :content="
            $t(
              'group_user_page.attributes_tab_item_signup_group_product_purchased_label_tooltip',
            )
          "
          :disabled="disabled"
        >
          <div>
            {{
              $t(
                "group_user_page.attributes_tab_item_signup_group_product_purchased_label",
              )
            }}
          </div>
        </TranslatableTooltip>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      v-if="userAttributes && userAttributes.length"
    >
      <v-flex class="xs12 text-center">
        <v-btn
          v-if="disabled"
          round
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          @click="$emit('edit')"
          :loading="loading || isLoading"
          >{{ $t("common.edit") }}</v-btn
        >
        <v-btn
          v-if="!disabled"
          round
          class="ml-0 mr-3 white sw-accent text-none"
          @click="$emit('cancel')"
          :loading="loading || isLoading"
          >{{ $t("common.cancel") }}</v-btn
        >
        <v-btn
          v-if="!disabled"
          round
          class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
          @click="$emit('save')"
          :loading="loading || isLoading"
          >{{ $t("common.save") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class="mb-4"
      v-if="!isLoading && userAttributes.length == 0"
    >
      <v-flex
        xs12
        class="text-xs-center"
      >
        {{ $t("no-attributes-created") }}
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CustomDatePicker from "@/components/CustomFields/CustomDatePicker.vue";
import moment from "moment-timezone";

export default {
  props: {
    pluginId: {
      type: [String, Number],
      required: true,
    },
    pluginPrefix: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    defaultAttributeValues: {},
    user: {},
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    userId() {
      return this.$route.params.user_id;
    },
    userAttributes: {
      get() {
        return this.$store.getters.userPageAttributes;
      },
      set(val) {
        this.$store.dispatch("setUserPageAttributes", val);
      },
    },
    attributeValues: {
      get() {
        return this.$store.getters.userPageAttributeValues;
      },
      set(val) {
        this.$store.dispatch("setUserPageAttributeValues", val);
      },
    },
    completeAttributeCategory: {
      get() {
        return this.$store.getters.completeAttributeCategory;
      },
      set(val) {
        this.$store.dispatch("setCompleteAttributeCategory", val);
      },
    },
  },
  components: {
    CustomDatePicker,
  },
  watch: {
    disabled(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.fetchAll();
      }
    },
  },
  mounted() {
    this.userAttributes = [];

    this.fetchAll();
  },
  methods: {
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getUserSilent();
        await this.listGroupUserRelatedAttributesSilent(this.attributeValues);

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async getUser() {
      try {
        this.isLoading = true;

        await this.getUserSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async getUserSilent() {
      const params = [this.groupId, this.userId, { with_attributes: 1 }];

      const response = await this.$api.groupUsers.get(...params);

      this.user = response.data.data;

      const attributes = response.data.data.attributes || {};

      this.defaultAttributeValues = JSON.parse(JSON.stringify(attributes));
      this.attributeValues = attributes;
    },
    async listGroupUserRelatedAttributesSilent(attributeValues) {
      const attributes = this.transformModelForSend(
        this.userAttributes,
        this.attributeValues,
      );

      const params = [
        this.groupId,
        {
          group_plugin_id: this.pluginId,
          answers: attributeValues || attributes,
        },
      ];

      const response = await this.$api.groupUserAttributes.related(...params);

      this.userAttributes = response.data.data
        .filter((el) => el.enabled !== 0)
        .map((el) => {
          if (el.type === "options") {
            const selectedItem = this.defaultAttributeValues[el.slug];

            el.options = el.options.map((opt) => {
              if (el.usage_available !== 0 || selectedItem === null) return opt;

              if (el.is_collection && selectedItem && selectedItem.length) {
                const contains = selectedItem.filter(
                  (v) => v === opt.id || v.id === opt.id,
                );

                if (contains[0]) opt.usage_available += 1;
              } else {
                if (selectedItem === opt.id) {
                  opt.usage_available += 1;
                }
              }
              return opt;
            });

            const attrs = attributeValues || attributes;

            //Reset values
            if (!attrs.hasOwnProperty(el.slug)) {
              this.attributeValues[el.slug] = null;
            }
          }

          return el;
        });
    },
    async listGroupUserRelatedAttributes() {
      try {
        this.isLoading = true;

        await this.listGroupUserRelatedAttributesSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    transformModelForSend(attributes, values) {
      let answers = {};

      for (let i = 0; i < attributes.length; i++) {
        const answer = attributes[i];

        //convert values

        // plain value, use as is
        if (answer.type === "varchar" || answer.type === "text") {
          answers[answer.slug] = values[answer.slug];
          continue;
        }

        // date
        if (answer.type === "datetime") {
          answers[answer.slug] = values[answer.slug]
            ? moment
                .tz(values[answer.slug], this.userTimezone)
                .format("YYYY-MM-DD 00:00:00")
            : null;
          continue;
        }

        // option object instead of ID given, use only ID
        if (answer.type === "options" && !answer.is_collection) {
          answers[answer.slug] = values[answer.slug]
            ? values[answer.slug].id || values[answer.slug]
            : null;
          continue;
        }

        // collection of option objects or IDs given, use only IDs
        if (answer.type === "options" && answer.is_collection) {
          answers[answer.slug] = values[answer.slug]
            ? values[answer.slug].map((el) => el.id || el)
            : null;
          continue;
        }
      }

      return answers;
    },
    availableOptionsSingleSelect(options, slug) {
      const value = this.defaultAttributeValues[slug];

      return options.filter((option) => {
        if (value && value == option.id) {
          return true;
        }

        if (option.usage_available !== 0) {
          return true;
        }
      });
    },
    availableOptionsMultiSelect(options, slug) {
      const value = this.defaultAttributeValues[slug];

      return options.filter((option) => {
        if (value) {
          const contains = value.filter((v) => v == option.id);

          if (contains[0]) return true;
        }

        if (option.usage_available !== 0) {
          return true;
        }
      });
    },
    removeAttributeOption(attribute, option) {
      if (!attribute || !option) {
        return;
      }

      if (attribute.is_collection) {
        this.attributeValues[attribute.slug] = this.attributeValues[
          attribute.slug
        ].filter((optionId) => optionId !== option.id);
      } else {
        this.attributeValues[attribute.slug] = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
