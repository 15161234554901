<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 class="mb-3 mt-5">
        <div v-if="isValid() && !multiple" class="selected-img-container">
          <img :src="selectedFile.url" alt="">
          <div class="close-btn" @click="removeSelectedImg"><v-icon>close</v-icon></div>
        </div>
        <v-btn
          round
          class="mx-0 sw-accent-bg sw-on-accent text-none"
          @click="isOpen = true"
          >{{ $t("select") }}</v-btn
        >
        <p v-if="!isValid()">No file selected</p>
      </v-flex>
    </v-layout>
    <div v-if="isOpen" class="media-manager">
      <div class="manager-window">
        <div class="close-btn" @click="isOpen = false"><v-icon>close</v-icon></div>
        <div class="tabs-container">
          <div
            class="tab"
            @click="activeTab = 'upload'"
            :class="{ active: activeTab === 'upload' }"
          >
            Upload
          </div>
          <div
            class="tab"
            @click="activeTab = 'library'"
            :class="{ active: activeTab === 'library' }"
          >
            Media Library
          </div>
        </div>

        <div class="main-container">
          <MediaUpload2 v-if="activeTab === 'upload'" @uploaded="handleUpload" />
          <MediaLibrary2
            v-if="activeTab === 'library'"
            :selectedFile="selectedFile"
            :selectedFilesList="selectedFilesList"
            :multiple="multiple"
            @input="handleSave"
            @change="handleChange"
            @show-upload="activeTab === 'upload'"
          />
        </div>
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import MediaUpload2 from "./MediaUpload2";
import MediaLibrary2 from "./MediaLibrary2";

export default {
  props: {
    value: [Array, Object],
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MediaUpload2,
    MediaLibrary2,
  },
  data: () => {
    return {
      activeTab: "library",
      selectedFile: null,
      selectedFilesList: [],
      isOpen: false,
    };
  },
  created() {
    this.selectedFile = !this.multiple && this.value ? this.value : null;
    this.selectedFilesList = this.multiple && Array.isArray(this.value) ? this.value : [];
  },
  methods: {
    handleUpload(file) {
      if(this.multiple) {
        this.selectedFilesList.push(file)
      } else {
        this.selectedFile = file;
      }
      this.activeTab = "library";
    },
    handleSave(data) {
      this.$emit("input", data);
      this.isOpen = false;
    },
    handleChange(data) {
      this.selectedFile = data;
    },
    isValid() {
      if (this.multiple) {
        return this.selectedFilesList.length > 0;
      }
      else {
        return !!this.selectedFile
      }
    },
    removeSelectedImg() {
      this.selectedFile = null;
      this.$emit("input", null);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-manager {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.overlay {
  width: 150vw;
  height: 150vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
}

.manager-window {
  width: 80vw;
  max-width: 1400px;
  margin: 10vh auto;
  height: fit-content;
  max-height: 80vh;
  background: #fff;
  position: relative;
  z-index: 2;
  border-radius: 20px;
  padding: 60px 40px;

  .tabs-container {
    display: flex;
    gap: 30px;
    margin-left: 80px;
  }

  .tab {
    font-size: 22px;
    cursor: pointer;

    &.active {
      border-bottom: 1px solid #ec5f54;
    }
  }

  .main-container {
    padding-top: 40px;
  }
}

.selected-img-container {
  width: 126px;
  height: 126px;
  border-radius: 10px;
  position: relative;
  margin: 0 auto 30px auto;
  
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
  }

  .close-btn {
    transform: scale(.6);
  }
}

.close-btn {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 34px;
  height: 34px;
  background: #ee6055;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  /deep/ .v-icon {
    color: #fff;
  }
}
</style>
