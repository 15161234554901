<template>
  <div v-if="userAttributePlugin.id && userAttributes.length" class="mb-5">
    <v-card
      class="border-radius mb-4 pt-5 px-4 pb-4"
      :class="{ disabled: isLoading }"
    >
      <v-layout class="row wrap">
        <v-flex class="xs12 mb-4 title font-weight-regular">
          {{ userAttributePlugin.name }}
        </v-flex>
        <v-flex class="xs12 mb-3">
          <v-layout
            v-if="userAttributes && userAttributes.length"
            class="row wrap"
          >
            <v-flex
              v-for="(attribute, i) in userAttributes"
              :key="'user-attribute-' + i"
              class="xs12"
            >
              <v-flex class="xs8 mb-4">
                <template v-if="attributeValues">
                  <v-select
                    v-if="
                      attribute.type === 'options' && !attribute.is_collection
                    "
                    v-model="attributeValues[attribute.slug]"
                    :items="
                      availableOptionsSingleSelect(
                        attribute.options,
                        attribute.slug,
                      )
                    "
                    item-text="name"
                    item-value="id"
                    :label="attribute.name"
                    :menu-props="{ closeOnContentClick: true }"
                    chips
                    small-chips
                    deletable-chips
                    @input="listGroupUserRelatedAttributes"
                    :disabled="!editMode"
                  ></v-select>
                  <v-select
                    v-if="
                      attribute.type === 'options' && attribute.is_collection
                    "
                    v-model="attributeValues[attribute.slug]"
                    :items="
                      availableOptionsMultiSelect(
                        attribute.options,
                        attribute.slug,
                      )
                    "
                    multiple
                    item-text="name"
                    item-value="id"
                    :label="attribute.name"
                    :menu-props="{ closeOnContentClick: true }"
                    chips
                    small-chips
                    deletable-chips
                    @input="listGroupUserRelatedAttributes"
                    :disabled="!editMode"
                  ></v-select>
                  <v-textarea
                    v-if="attribute.type == 'text'"
                    v-model="attributeValues[attribute.slug]"
                    :label="attribute.name"
                    no-resize
                    :disabled="!editMode"
                  ></v-textarea>
                  <v-text-field
                    v-if="
                      attribute.type == 'varchar' || attribute.type == 'integer'
                    "
                    v-model="attributeValues[attribute.slug]"
                    :label="attribute.name"
                    :disabled="!editMode"
                  ></v-text-field>
                  <CustomDatePicker
                    v-if="attribute.type === 'datetime'"
                    v-model="attributeValues[attribute.slug]"
                    :label="attribute.name"
                    :disabled="!editMode"
                  >
                  </CustomDatePicker>
                </template>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs12 text-center">
          <v-btn
            v-if="!editMode"
            round
            class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
            @click="editMode = true"
            :loading="isLoading"
            >{{ $t("common.edit") }}</v-btn
          >
          <v-btn
            v-if="editMode"
            round
            class="ml-0 mr-3 white sw-accent text-none"
            @click="closeEditMode"
            :loading="isLoading"
            >{{ $t("common.cancel") }}</v-btn
          >
          <v-btn
            v-if="editMode"
            round
            class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
            @click="updateUser"
            :loading="isLoading"
            >{{ $t("common.save") }}</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import CustomDatePicker from "@/components/CustomFields/CustomDatePicker.vue";
import moment from "moment-timezone";

export default {
  data: () => ({
    isLoading: false,
    editMode: false,
    userAttributePlugin: {},
    defaultAttributeValues: {},
    attributeValues: {},
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    userId() {
      return this.$route.params.user_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    userAttributes: {
      get() {
        return this.$store.getters.userPageProperties;
      },
      set(val) {
        this.$store.dispatch("setUserPageProperties", val);
      },
    },
  },
  components: {
    CustomDatePicker,
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.listGroupUserAttributePluginSilent();
        await this.getUserSilent();
        await this.listGroupUserRelatedAttributesSilent(this.attributeValues);

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    async listGroupUserAttributePluginSilent() {
      const params = [
        this.groupId,
        {
          prefix: "guestattributes",
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.list(...params);

      const responseData = response.data.data || [];

      if (!responseData.length) return;

      this.userAttributePlugin = responseData[0];
    },
    async getUserSilent() {
      const params = [this.groupId, this.userId, { with_attributes: 1 }];

      const response = await this.$api.groupUsers.get(...params);

      const attributes = response.data.data.attributes || {};

      this.defaultAttributeValues = JSON.parse(JSON.stringify(attributes));
      this.attributeValues = attributes;
    },
    transformModelForSend(attributes, values) {
      let answers = {};

      for (let i = 0; i < attributes.length; i++) {
        const answer = attributes[i];

        //convert values

        // plain value, use as is
        if (answer.type === "varchar" || answer.type === "text") {
          answers[answer.slug] = values[answer.slug];
          continue;
        }

        // date
        if (answer.type === "datetime") {
          answers[answer.slug] = values[answer.slug]
            ? moment
                .tz(values[answer.slug], this.userTimezone)
                .format("YYYY-MM-DD 00:00:00")
            : null;
          continue;
        }

        // option object instead of ID given, use only ID
        if (answer.type === "options" && !answer.is_collection) {
          answers[answer.slug] = values[answer.slug]
            ? values[answer.slug].id || values[answer.slug]
            : null;
          continue;
        }

        // collection of option objects or IDs given, use only IDs
        if (answer.type === "options" && answer.is_collection) {
          answers[answer.slug] = values[answer.slug]
            ? values[answer.slug].map((el) => el.id || el)
            : null;
          continue;
        }
      }

      return answers;
    },
    async listGroupUserRelatedAttributesSilent(attributeValues) {
      if (!this.userAttributePlugin || !this.userAttributePlugin.id) {
        return;
      }

      const attributes = this.transformModelForSend(
        this.userAttributes,
        this.attributeValues,
      );

      const params = [
        this.groupId,
        {
          group_plugin_id: this.userAttributePlugin.id,
          answers: attributeValues || attributes,
        },
      ];

      const response = await this.$api.groupUserAttributes.related(...params);

      this.userAttributes = response.data.data
        .filter((el) => el.enabled !== 0)
        .map((el) => {
          if (el.type === "options") {
            const selectedItem = this.defaultAttributeValues[el.slug];

            el.options = el.options.map((opt) => {
              if (el.usage_available !== 0 || selectedItem === null) return opt;

              if (el.is_collection && selectedItem && selectedItem.length) {
                const contains = selectedItem.filter(
                  (v) => v === opt.id || v.id === opt.id,
                );

                if (contains[0]) opt.usage_available += 1;
              } else {
                if (selectedItem === opt.id) {
                  opt.usage_available += 1;
                }
              }
              return opt;
            });
          }

          return el;
        });
    },
    async listGroupUserRelatedAttributes() {
      try {
        this.isLoading = true;

        await this.listGroupUserRelatedAttributesSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async updateUser() {
      try {
        const isValid = await this.$validator.validate();

        if (!isValid) return;

        const attributes = this.transformModelForSend(
          this.userAttributes,
          this.attributeValues,
        );

        const specs = [this.userId, attributes];

        this.isLoading = true;

        await this.$api.users.update(...specs);

        this.isLoading = false;
        this.editMode = false;

        this.$store.dispatch("addNotification", {
          message: this.$t("userUpdated"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    availableOptionsSingleSelect(options, slug) {
      const value = this.defaultAttributeValues[slug];

      return options.filter((option) => {
        if (value && value == option.id) {
          return true;
        }

        if (option.usage_available !== 0) {
          return true;
        }
      });
    },
    availableOptionsMultiSelect(options, slug) {
      const value = this.defaultAttributeValues[slug];

      return options.filter((option) => {
        if (value) {
          const contains = value.filter((v) => v == option.id);

          if (contains[0]) return true;
        }

        if (option.usage_available !== 0) {
          return true;
        }
      });
    },
    async closeEditMode() {
      try {
        this.editMode = false;

        this.isLoading = true;

        await this.getUserSilent();

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
