<template>
  <div class="push-destinations">
    <div class="mb-4">
      <TranslatableTooltip
        :content="$t('group_message_template_wizard.destination_label_tooltip')"
      >
        <div class="sw-h5">
          {{ $t("group_message_template_wizard.destination_label") }}
        </div>
      </TranslatableTooltip>
    </div>
    <v-card v-if="model && model.length" flat>
      <v-layout row class="mb-4 align-center">
        <v-flex class="xs12">
          <!-- @search:focus="fetch" -->
          <vue-select
            v-model="model[0]"
            :options="items"
            :placeholder="$t('selectDestinationPlaceholder')"
            :clearable="false"
            appendToBody
            @search="searchFunc"
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                v-bind="attributes"
                v-on="events"
                :style="{ minHeight: '28px', lineHeight: '28px' }"
              />
            </template>
            <template v-slot:selected-option="option">
              <div
                v-if="!isLoading"
                :style="{ minHeight: '28px', lineHeight: '28px' }"
              >
                {{ option.label }} - ({{
                  getComponentDescription(option.component)
                }})
              </div>
              <div v-if="isLoading">
                ({{ getComponentDescription(option.component) }})
              </div>
            </template>
            <template v-slot:option="option">
              <div :style="{ minHeight: '28px', lineHeight: '28px' }">
                {{ option.label }} - ({{
                  getComponentDescription(option.component)
                }})
              </div>
            </template>
            <template v-slot:open-indicator>
              <v-icon class="arrow_down">arrow_drop_down</v-icon>
              <v-icon class="arrow_up sw-accent">arrow_drop_up</v-icon>
            </template>
          </vue-select>
        </v-flex>
        <v-btn icon class="ml-3 mr-0 my-0" @click="removeDestination()">
          <font-awesome-icon class="sw-accent" icon="trash" />
        </v-btn>
      </v-layout>
    </v-card>
    <v-text-field
      v-if="model[0]"
      v-model="model[0].name"
      :label="'Text on button'"
    ></v-text-field>
    <v-btn
      v-if="!model || !model.length"
      round
      class="mx-0 sw-secondary-bg sw-on-secondary text-none"
      @click="addDestination"
      >{{ $t("addDestinationBtnText") }}</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { components } from "@/supported-components.json";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    dialog: false,
    timeout: null,
    search: null,
    perPage: 3,
    pluginDestinations: [],
    articleDestinations: [],
    ticketTemplateDestinations: [],
  }),
  computed: {
    ...mapGetters(["appContentLanguage"]),
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    items() {
      return [
        ...this.pluginDestinations,
        ...this.articleDestinations,
        ...this.ticketTemplateDestinations,
      ];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.updateModelValue();
      this.fetch();
    });
  },
  methods: {
    addDestination() {
      this.model.push(null);
    },
    removeDestination() {
      this.model = [];
    },
    getComponentDescription(componentName) {
      const component = components.find(
        (el) =>
          el.menu_slug === "ionic-app-sidemenu" && el.entity === componentName,
      );

      if (component) {
        return this.$t(`destination${component.entity}`);
      }
    },
    pluginsTransformer(plugins) {
      if (!plugins || !plugins.length) {
        return [];
      }

      const destinations = [];

      for (const [i, plugin] of plugins.entries()) {
        const defaultData = {
          group_plugin_id: plugin.id,
          label: plugin.name,
          name: plugin.name,
          params: null,
        };

        if (plugin.prefix === "article") {
          destinations.push({
            component: "ArticleListPage",
            ...defaultData,
          });
        }

        if (plugin.prefix === "message") {
          destinations.push({
            component: "MessageListPage",
            ...defaultData,
          });
        }

        if (plugin.prefix === "questionwizard") {
          destinations.push({
            component: "QuestionPage",
            ...defaultData,
          });
        }

        if (plugin.prefix === "ticketwizard") {
          destinations.push(
            {
              component: "TicketListPage",
              ...defaultData,
            },
            {
              component: "InventoryPage",
              ...defaultData,
            },
          );
        }
      }

      return destinations;
    },
    articlesTransformer(articles) {
      if (!articles || !articles.length) {
        return [];
      }

      return articles.map((el, i) => ({
        component: "ArticlePage",
        group_plugin_id: el.group_plugin_id,
        label: el.name,
        name: el.name,
        params: {
          article_id: el.id,
        },
      }));
    },
    ticketTemplatesTransformer(ticketTemplates) {
      if (!ticketTemplates || !ticketTemplates.length) {
        return [];
      }

      return ticketTemplates.map((el, i) => ({
        component: "TicketPage",
        group_plugin_id: el.group_plugin_id,
        label: el.name,
        name: el.name,
        params: {
          ticket_template_id: el.id,
        },
      }));
    },
    async fetch() {
      const params = [
        this.groupId,
        {
          lang: this.appContentLanguage,
          search: this.search,
          per_page: this.perPage,
        },
      ];

      this.isLoading = true;

      const groupPluginsListResponse = await this.$api.groupPlugins.list(
        ...params,
      );

      this.pluginDestinations = this.pluginsTransformer(
        groupPluginsListResponse.data.data,
      );

      const groupArticlesListResponse = await this.$api.groupArticles.list(
        ...params,
      );

      this.articleDestinations = this.articlesTransformer(
        groupArticlesListResponse.data.data,
      );

      const groupTicketTemplatesListResponse =
        await this.$api.groupTicketTemplates.list(...params);

      this.ticketTemplateDestinations = this.ticketTemplatesTransformer(
        groupTicketTemplatesListResponse.data.data,
      );

      this.isLoading = false;
    },
    searchFunc(val) {
      this.search = val;

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.fetch();
      }, 500);
    },
    updateModelValue() {
      if (!this.model || !this.model[0]) return;

      if (!this.model[0].params) {
        this.$api.groupPlugins
          .get(this.groupId, this.model[0].group_plugin_id, {
            lang: this.appContentLanguage,
          })
          .then((res) => {
            this.model[0].label = res.data.data.name;
          });

        return;
      }

      if (this.model[0].params.article_id) {
        this.$api.groupArticles
          .get(this.groupId, this.model[0].params.article_id, {
            lang: this.appContentLanguage,
          })
          .then((res) => {
            this.model[0].label = res.data.data.name;
          });

        return;
      }

      if (this.model[0].params.ticket_template_id) {
        this.$api.groupTicketTemplates
          .get(this.groupId, this.model[0].params.ticket_template_id, {
            lang: this.appContentLanguage,
          })
          .then((res) => {
            this.model[0].label = res.data.data.name;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.push-destinations {
  .v-select {
    padding: 0 !important;
    border: 0 !important;
    font-family: "Poppins", sans-serif;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      border-color: rgba(0, 0, 0, 0.42);
      border-style: solid;
      border-width: thin 0 0 0;
      -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    &:after {
      content: "";
      bottom: -1px;
      left: 0;
      position: absolute;
      width: 100%;
      -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      border-color: currentColor;
      border-style: solid;
      border-width: thin 0 thin 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
    }

    .vs__search,
    .vs__selected,
    .vs__selected-options {
      margin: 0;
      padding: 0;
    }

    .vs__selected {
      font-size: 16px;
    }
  }

  .vs__actions {
    padding: 0;
  }

  .arrow_up {
    display: none;
  }

  .vs--open {
    &:after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
    .arrow_up {
      display: inline-block;
    }
    .arrow_down {
      display: none;
    }
  }
}

[id$="__listbox"] {
  font-family: "Poppins", sans-serif;
  list-style-type: none;
  padding: 8px 0 8px;
  -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  margin-bottom: 2px;
  border-radius: 0;
  border: none;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  .vs__dropdown-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 48px;
    margin: 0;
    padding: 0 16px;
    color: inherit;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    text-decoration: none;
    -webkit-transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .vs__dropdown-option--selected {
    background: lighten($primary, 16%) !important;
    color: #fff;

    &:hover {
      background: lighten($primary, 24%) !important;
    }
  }

  .vs__dropdown-option--highlight {
    background: #fafafa;
  }
}
</style>
