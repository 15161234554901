<template>
  <div class="group_message_template_wizard-message">
    <v-form data-vv-scope="message">
      <v-layout>
        <v-flex xs8 class="pr-5">
          <v-layout row wrap class="mb-5">
            <v-flex
              v-if="
                isType('sms') &&
                activeGroup.attributes &&
                activeGroup.attributes.sms_sender_name
              "
              xs12
              class="mb-5"
            >
              <TranslatableTooltip
                :content="
                  $t('group_message_template_wizard.sender_name_label_tooltip')
                "
              >
                <div class="sw-h5">
                  {{ $t("group_message_template_wizard.sender_name_label") }}
                </div>
              </TranslatableTooltip>
              <div class="sw-caption">
                {{ activeGroup.attributes.sms_sender_name }}
              </div>
            </v-flex>
            <v-flex xs12>
              <TranslatableTooltip
                :content="
                  $t('group_message_template_wizard.name_label_tooltip')
                "
              >
                <div class="sw-h5">
                  {{ $t("group_message_template_wizard.name") }}
                </div>
              </TranslatableTooltip>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="messageTemplate.name"
                v-validate.disable="'required|min:3'"
                :error-messages="errors.collect('name')"
                :data-vv-name="'name'"
                :data-vv-as="$tc('name', 2)"
                :placeholder="
                  $t(
                    `group_message_template_wizard.${messageType}.name_placeholder`,
                  )
                "
                hide-details
              />
              <ErrorMessages :error-messages="errors.collect('message.name')" />
            </v-flex>
          </v-layout>
          <v-layout v-if="isType('email')" row wrap class="mb-5">
            <v-flex xs12>
              <TranslatableTooltip
                :content="
                  $t('group_message_template_wizard.subject_label_tooltip')
                "
              >
                <div class="sw-h5">
                  {{ $t("group_message_template_wizard.subject") }}
                </div>
              </TranslatableTooltip>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="messageTemplate.subject"
                v-validate.disable="'required|min:3'"
                :error-messages="errors.collect('subject')"
                data-vv-name="subject"
                :data-vv-as="$tc('subject', 2)"
                :placeholder="
                  $t('group_message_template_wizard.subject_placeholder')
                "
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('message.subject')"
              />
            </v-flex>
          </v-layout>
          <v-layout v-if="isType('push')" row wrap class="mb-5">
            <v-flex xs12>
              <TranslatableTooltip
                :content="
                  $t('group_message_template_wizard.title_label_tooltip')
                "
              >
                <div class="sw-h5">
                  {{ $t("group_message_template_wizard.title") }}
                </div>
              </TranslatableTooltip>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="messageTemplate.subject"
                v-validate.disable="'required|min:3'"
                :error-messages="errors.collect('title')"
                data-vv-name="title"
                :data-vv-as="$tc('title', 2)"
                :placeholder="
                  $t('group_message_template_wizard.title_placeholder')
                "
                hide-details
              ></v-text-field>
              <ErrorMessages
                :error-messages="errors.collect('message.title')"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="isType('email') || isType('push')" xs4 class="pl-5">
          <v-card flat class="border-radius sw-primary-bg">
            <v-card-text class="py-5 text-center">
              <TranslatableTooltip
                v-if="isType('email')"
                :content="
                  $t('group_message_template_wizard.email_image_label_tooltip')
                "
                class="center"
              >
                <div class="mb-2 sw-h5">
                  {{ $t("group_message_template_wizard.message_image_label") }}
                </div>
              </TranslatableTooltip>
              <TranslatableTooltip
                v-if="isType('push')"
                :content="
                  $t('group_message_template_wizard.push_image_label_tooltip')
                "
                class="center"
              >
                <div class="mb-2 sw-h5">
                  {{ $t("group_message_template_wizard.message_image_label") }}
                </div>
              </TranslatableTooltip>
              <MediaManager
                v-model="messageTemplate.message_image"
                :media-type="'images'"
                label=""
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 class="mb-4">
          <TranslatableTooltip
            v-if="isType('email')"
            :content="
              $t('group_message_template_wizard.email_body_label_tooltip')
            "
          >
            <div class="sw-h5">
              {{ $t("group_message_template_wizard.body") }}
            </div>
          </TranslatableTooltip>
          <TranslatableTooltip
            v-if="isType('sms')"
            :content="
              $t('group_message_template_wizard.sms_body_label_tooltip')
            "
          >
            <div class="sw-h5">
              {{ $t("group_message_template_wizard.body") }}
            </div>
          </TranslatableTooltip>
          <TranslatableTooltip
            v-if="isType('push')"
            :content="
              $t('group_message_template_wizard.push_body_label_tooltip')
            "
          >
            <div class="sw-h5">
              {{ $t("group_message_template_wizard.body") }}
            </div>
          </TranslatableTooltip>
        </v-flex>
        <v-flex xs12 class="mb-3">
          <QuillEditor
            v-model="messageTemplate.body"
            v-validate.disable="'required'"
            :error-messages="errors.collect('body')"
            :data-vv-name="'body'"
            :data-vv-as="$t('messageBodyAs')"
            :config="editorConfig"
            :showLoginLink="messageType === 'email'"
          />
          <ErrorMessages :error-messages="errors.collect('message.body')" />
        </v-flex>
      </v-layout>
      <v-layout row wrap class="pt-3">
        <v-flex xs12 class="mb-4">
          <v-layout row wrap>
            <v-flex xs12 class="mb-2">
              {{ $t("group_message_template_wizard.send_time_start") }}
            </v-flex>
            <v-flex class="xs4 pr-5">
              <date-picker
                v-model="messageTemplate.send_time_start"
                @input="updateSendTimeEnd"
                width="99%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                format="MMMM DD, YYYY H:mm"
                :minute-step="5"
                :placeholder="
                  $t(
                    'group_message_template_wizard.send_time_start_placeholder',
                  )
                "
                :editable="false"
              ></date-picker>
            </v-flex>
            <v-flex class="xs4 pl-5">
              <date-picker
                v-model="messageTemplate.send_time_end"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                format="MMMM DD, YYYY H:mm"
                :minute-step="5"
                :not-before="messageTemplate.send_time_start"
                :placeholder="
                  $t('group_message_template_wizard.send_time_end_placeholder')
                "
                :editable="false"
              ></date-picker>
            </v-flex>
            <v-flex class="xs12 px-2 pt-3 grey--text caption"
              >{{ $t("current-timezone") }}: {{ userTimezone }}</v-flex
            >
          </v-layout>
        </v-flex>
        <v-flex v-if="isType('push')" xs12 class="pt-4">
          <PushDestinations
            v-model="messageTemplate.destinations"
            :group-id="groupId"
          />
          <div
            v-show="
              messageTemplate.destinations &&
              messageTemplate.destinations.length
            "
            class="mb-2 caption grey--text"
          >
            {{ $t("pushDestinationDescriptionText") }}
          </div>
        </v-flex>
        <v-flex v-if="isType('push')" xs12 class="pt-4">
          <v-switch
            v-model="messageTemplate.is_hidden"
            :true-value="0"
            :false-value="1"
          >
            <template slot="label">
              {{ $t("group_message_template_wizard.is_hidden_label") }}
            </template>
          </v-switch>
        </v-flex>
        <v-flex xs12 class="pt-4">
          <tooltip name="InactiveMessage">
            <v-switch
              v-model="messageTemplate.active"
              :true-value="1"
              :false-value="0"
            >
              <template slot="label">
                {{ $t("status") }}
              </template>
            </v-switch>
          </tooltip>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import DatePicker from "vue2-datepicker";
import PushDestinations from "@/components/GroupMessageTemplateWizard/MessageTemplate/PushDestinations.vue";

export default {
  inject: ["parentValidator"],
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    activeGroup() {
      return this.$store.getters.activeGroup || {};
    },
    messageTemplate: {
      get() {
        return this.$store.getters.groupMessageTemplateWizardTemplate;
      },
      set(val) {
        this.$store.dispatch("setGroupMessageTemplateWizardTemplate", val);
      },
    },
    messageType() {
      return this.messageTemplate.type || this.$route.query.type;
    },
    editorConfig() {
      return {
        toolbar: this.messageType !== "sms",
        iframes: this.messageType === "push",
        label: "",
        placeholder: "Insert text here",
        shortcodes: true,
        style: {
          height: "400px",
          "--primary-color": "#1f363d",
          "--secondary-color": "#3869D4",
        },
      };
    },
  },
  components: {
    DatePicker,
    PushDestinations,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    isType(type) {
      if (!type || !this.messageType) {
        return;
      }

      return this.messageType === type;
    },
    updateSendTimeEnd() {
      if (!this.messageTemplate.send_time_end) return;

      const minTill = moment(this.messageTemplate.send_time_start)
        .add(24, "hours")
        .toISOString();

      const tillIsSameOrBeforeMinTill = moment(
        this.messageTemplate.send_time_end,
      ).isSameOrBefore(minTill);

      if (
        tillIsSameOrBeforeMinTill ||
        this.messageTemplate.send_time_end === null
      ) {
        this.messageTemplate.send_time_end = minTill;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
