<template>
  <div class="media-upload">
    <vue-dropzone
      id="dropzone"
      ref="dropzone"
      :options="dropzoneOptions"
      :useCustomSlot="true"
      :class="{hidden: selectedFile}"
      @vdropzone-thumbnail="onImageLoaded"
    >
      <div class="dropzone-custom-content">
        <div class="arrow-container">
          <font-awesome-icon icon="arrow-up" />
        </div>

        <h2>Drag and drop files here</h2>
        <p>Files supported are PNG, JPG and GIF</p>
        <div class="btn save">Choose file</div>
        <p>Recommended size 600 x 600 pixels</p>
        
      </div>
    </vue-dropzone>
    <template v-if="selectedFile">
      <cropper
        class="cropper"
        ref="cropper"
        :src="selectedFile"
        @change="updateCrop"
      />

      <div class="buttons-container">
        <div class="btn cancel" @click="cancel">Cancel</div>
        <div class="btn save" @click="save">Save</div>
      </div>
    </template>

    
  </div>
</template>

<script>

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';


export default {
  data: () => ({
    dropzoneOptions: {
      url: process.env.VUE_APP_API_URL + "media",
      autoProcessQueue: false,
      thumbnailWidth: null,
      thumbnailHeight: null,
    },
    selectedFile: null,
    croppedImage: null,
    filename: "filename.png",
  }),
  components: {
    vueDropzone: vue2Dropzone,
    Cropper,
  },
  methods: {
    onImageLoaded(file, dataUrl) {
      this.selectedFile = dataUrl;
      this.filename = file.name || this.filename;
    },
    updateCrop({canvas}) {
      this.croppedImage = this.dataURItoBlob(canvas.toDataURL());
    },
    async save() {
      var bodyFormData = new FormData();
      bodyFormData.append('name', 'testimage.png');
      bodyFormData.append('file', this.croppedImage);
      const response = await this.$api.media.upload(bodyFormData)
      this.$emit("uploaded", response.data.data);
      this.$refs.dropzone.removeAllFiles();
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);
      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
    },
    cancel() {
      this.$refs.dropzone.removeAllFiles();
      this.selectedFile = null;
      this.croppedImage = null;
    },
  },
}
</script>

<style lang="scss" scoped>

.media-upload {
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  /deep/ .vue-dropzone {
    width: 70%;
    border: 1px dashed #1F363D;
    border-radius: 10px;

    .dz-message {
      margin: 0;
    }
  }

  .arrow-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EE6055;
    color: #fff;
    font-size: 28px;
  }

  #dropzone.hidden {
    display: none;
  }
  .cropper {
    width: 600px;
  }

  .buttons-container {
    display: flex;
    gap: 30px;
  }

  .btn {
    padding: 7px 16px;
    font-weight: 500;
    font-size: 20px;
    border-radius: 22px;
    cursor: pointer;

    &.cancel {
      color: #EC5F54;
      box-shadow: 0 0 4px 1px rgba(0,0,0,.2);
    }

    &.save {
      background: #EC5F54;
      color: #fff;
    }
  }

  .dropzone-custom-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0;

    p {
      margin-bottom: 0 !important;
    }

    .btn {
      margin-top: 50px;
    }
  }
}
</style>
