import "@babel/polyfill";
import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store/core";
import "./registerServiceWorker";
import axios from "axios";
import VeeValidate from "vee-validate";
import VueProgressBar from "vue-progressbar";
import confirmDialog from "vuetify-vuejs-confirmdialog";
import "@/fontAwesomeIcons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);

import vSelect from "vue-select";

Vue.component("vue-select", vSelect);
import "vue-select/dist/vue-select.css";

// import Pusher from "pusher-js"
window.Pusher = require("pusher-js");

import api from "@/api/api";

Vue.prototype.$api = api;

import i18n from "./i18n";

try {
  require("@/assets/styles/styles.scss");

  const theme = process.env.VUE_APP_THEME;

  if (theme) {
    require(`@/assets/themes/${theme}/styles.scss`);
  }
} catch (e) {}

try {
  require("@/mixins/vuexloading.js");
  require("@/mixins/globalMixin.js");
} catch (e) {}

Vue.use(confirmDialog);
Vue.use(VueProgressBar, {});

import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import "overlayscrollbars/css/OverlayScrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";

Vue.component("overlay-scrollbars", OverlayScrollbarsComponent);

import ErrorMessages from "@/components/ErrorMessages.vue";
Vue.component("ErrorMessages", ErrorMessages);

import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
Vue.component("ConfirmationDialog", ConfirmationDialog);

import PageTitle from "@/components/PageTitle.vue";
Vue.component("PageTitle", PageTitle);

import VideoTutorial from "@/components/Tutorials/VideoTutorial.vue";
Vue.component("VideoTutorial", VideoTutorial);

import AppSpeedDial from "@/components/AppSpeedDial.vue";
Vue.component("AppSpeedDial", AppSpeedDial);

import QuillEditor from "@/components/QuillEditor.vue";
Vue.component("QuillEditor", QuillEditor);

import MediaManager from "@/components/Media/MediaManager.vue";
Vue.component("MediaManager", MediaManager);

import MediaManager2 from "@/components/Media/MediaManager2.vue";
Vue.component("MediaManager2", MediaManager2);

import IconSelector from "@/components/IconSelector.vue";
Vue.component("IconSelector", IconSelector);

import Tooltip from "@/components/Tooltip/Tooltip.vue";
Vue.component("Tooltip", Tooltip);

import TranslatableTooltip from "@/components/Tooltip/TranslatableTooltip.vue";
Vue.component("TranslatableTooltip", TranslatableTooltip);

import validationMessagesEn from "vee-validate/dist/locale/en";
import validationMessagesDa from "vee-validate/dist/locale/da";
import validationMessagesRu from "vee-validate/dist/locale/ru";
import validationMessagesLv from "vee-validate/dist/locale/lv";

Vue.use(VeeValidate, {
  i18nRootKey: "validations", // customize the root path for validation messages.
  i18n,
  dictionary: {
    en: validationMessagesEn,
    da: validationMessagesDa,
    ru: validationMessagesRu,
    lv: validationMessagesLv,
  },
});

import { Validator } from "vee-validate";

Validator.extend("allowed_domains", {
  validate: (value, options) => {
    const email = value.split("@");
    const domain = email[1];

    if (!domain) {
      return false;
    }

    return options.includes(domain);
  },
});

Validator.extend("domain", {
  validate: (value) => {
    const domainRegex =
      /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/;

    const domains = value.split(",");

    return domains.every((domain) => domainRegex.test(domain.trim()));
  },
});

Validator.extend("price", {
  validate: (value) => {
    const priceRegex = /^\d+(?:\.\d+)?$/g;

    return priceRegex.test(value);
  },
});

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

let app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = store.getters.getToken;

    if (token) {
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  },
);
